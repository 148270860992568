const l = document.location;
let pathName = l.pathname;
if (pathName === '/en' || pathName.startsWith('/en/')) {
  pathName = pathName.substring(3);
}

function detectLanguage() {
  const appOK = ['fr', 'de', 'it'];

  const params = new URLSearchParams(l.search);
  const paramLang = params.get('lang');
  if (paramLang && appOK.includes(paramLang)) {
    return paramLang;
  }

  const languages = navigator.languages || [navigator.language];
  const userOK = languages.map((item) => item.substring(0, 2));
  const ok = userOK.filter((item) => appOK.includes(item));
  return ok[0] || 'de';
}
const lang = detectLanguage();
l.pathname = `/${lang}${pathName}`;
